<template>
  <div id="detail">
    <div class="title">
      <van-image width="100%" fit="contain" :src="backUrl" />
      <div class="title-info">
        <span class="tips" :style="{ backgroundColor: '#FFA939' }">{{
          type | filterTitle
        }}</span>
        <div class="des">{{ type | filterDes }}</div>
      </div>
    </div>
    <van-tabs v-model="activeName" @click="onClick">
      <van-tab
        v-for="(item, index) in tabList[type - 1]"
        :key="index"
        :title="item.label"
        :name="item.name"
      >
      </van-tab>
      <div class="content" v-html="content"></div>
    </van-tabs>
  </div>
</template>

<script>
import api from '../../common/js/api';
export default {
  props: {},
  filters: {
    filterTitle(type) {
      let title = '';
      switch (type) {
        case '1':
          title = '身高素质';
          break;
        case '2':
          title = '体质免疫力';
          break;
        case '3':
          title = '社交能力';
          break;
        default:
          title = '';
      }
      return title;
    },
    filterDes(val) {
      let content = '';
      switch (val) {
        case '1':
          content =
            '儿童身高是及时掌握生长发育状况的重要手段之一。身高增长正常，说明生长发育良好；身高增长缓慢、基本不长、甚至停止增长就要分析原因，并采取相应的措施进行针对性补救。';
          break;
        case '2':
          content =
            '免疫力是人体自身的防御机制，是人体识别和消灭外来侵入的任何异物（病毒、细菌等）。处理衰老、损伤、死亡、变性的自身细胞以及识别和处理体内突变细胞和病毒感染细胞的能力。';
          break;
        case '3':
          content =
            '在3~6岁这个期间是孩子的社交敏感期，也是锻炼孩子的社交能力的最佳时期，这一段时间我们家长要了解孩子的社交能力发展的特点，要用正确的方法，正确的态度来对待孩子，避免家长因为不懂心理学，误解孩子，并且给孩子带来伤害。';
          break;
        default:
          content = '';
      }
      return content;
    },
  },
  data() {
    return {
      backUrl: require('../../common/img/detail1.png'),
      des:
        '儿童身高是及时掌握生长发育状况的重要手段之一。身高增长正常，说明生长发育良好;身高增长缓慢、基本不长、甚至停止增长就要分析',
      activeName: '',
      tabList: [
        [
          {
            name: '0',
            label: '遗传',
          },
          { name: '1', label: '营养' },
          { name: '2', label: '运动' },
          { name: '3', label: '睡眠' },
        ],
        [
          { name: '4', label: 'BMI' },
          { name: '5', label: '运动' },
          { name: '6', label: '心理状态' },
          { name: '7', label: '营养' },
        ],
        [
          { name: '8', label: '语言表达' },
          { name: '9', label: '体育运动' },
        ],
      ],
      content: '',
      type: this.$route.query.type,
    };
  },
  computed: {},
  created() {
    this.type = this.$route.query.type;
    this.activeName = this.tabList[this.type - 1][0].name;
    this.renderData();
  },
  mounted() {},
  watch: {},
  methods: {
    onClick() {
      this.renderData();
    },
    renderData() {
      let params = {
        type: this.activeName,
      };
      this.$post(api.pictureService, params).then((res) => {
        let data = res.data;
        this.content = data.content;
      });
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
#detail /deep/ .van-tabs__line {
  background: #2788ff;
}
#detail /deep/ .van-tabs__content {
  height: 70%;
  overflow: auto;
}
#detail {
  height: 100%;
  background: #fff;
  padding: 10px 18px;
  box-sizing: border-box;
  .title {
    position: relative;
    .title-info {
      position: absolute;
      bottom: 24px;
      font-size: 11px;
      color: #fff;
      padding: 0 14px;

      .tips {
        padding: 4px 4px;
        border-radius: 5px;
      }
      .des {
        margin-top: 10px;
        text-indent: 2em;
      }
    }
  }
  .content {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    height: 65vh;
    overflow: auto;
    margin-top: 10px;
  }
}
</style>
